import { createStore } from 'vuex';
import router from '@/router';  // 确保正确地导入了 router

export default createStore({
    state: {
        userInfo: null,
        locations: [],
    },
    getters: {
        isAuthenticated: state => !!state.userInfo,
        isManager: state => !!state.userInfo && state.userInfo.role === 'manager',
        isSupervisor: (state, getters) => {
            return getters.uniqueSupervisors.includes(state.userInfo?.username);
        },
        uniqueSupervisors: state => {
            const supervisorsSet = new Set();
            state.locations.forEach(location => {
                location.supervisors.forEach(supervisor => {
                    supervisorsSet.add(supervisor);
                });
            });
            return Array.from(supervisorsSet);
        }
    },

    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        clearUserInfo(state) {
            state.userInfo = null;
        },
        setLocations(state, locations) {
            state.locations = locations || [];
        },
    },
    actions: {
        logout({ commit }) {
            commit('clearUserInfo');
            router.push('/login');
        }
    },
    plugins: [
        store => {
            store.subscribe((mutation, state) => {
                if (mutation.type === "clearUserInfo") {
                    // 如果 userInfo 被清除，可以在这里执行任何清理操作
                    if (!state.userInfo) {
                        router.push('/login').catch(err => {
                            // 处理路由异常，例如重复导航到相同的路径
                            console.error(err);
                        });
                    }
                }
            });
        }
    ]
});
