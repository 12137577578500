<template>
  <a-layout class="mobile-check-in-layout">
    <a-layout-header class="mobile-check-in-header">
      <div class="header-content">
        <h1>チェックイン</h1> <!-- 考勤记录 -->
      </div>
    </a-layout-header>
    <a-form-item label="当日基本給">
      <a-tag color="black">¥{{ baseDailyWage.toLocaleString() }}</a-tag>
    </a-form-item>
    <a-layout-content style="padding: 24px;">
      <a-card title="チェックイン" bordered :loading="isSubmitting">
        <a-form>
          <!-- 地点选择 -->
          <a-form-item label="場所">
            <a-select v-model:value="selectedLocation" :options="locationOptions" placeholder="場所を選択してください" />
          </a-form-item>
          <!-- 日期选择器 -->
          <a-form-item label="日付">
            <a-date-picker
                v-model:value="selectTime"
                format="YYYY-MM-DD"
                :disabled-date="disabledDate"
            />
          </a-form-item>
          <!-- 出勤时间选择器 -->
          <a-form-item label="出勤時間">
            <a-select v-model:value="attendanceStatus" placeholder="出勤時間を選択してください">
              <a-select-option value="1天">1日</a-select-option>
              <a-select-option value="半天">半日</a-select-option>
              <a-select-option value="leave">休暇</a-select-option>
              <a-select-option value="none">その他</a-select-option>
            </a-select>
          </a-form-item>
          <!-- 费用输入 -->
          <a-form-item label="飲料費">
            <a-input-number v-model:value="drinkExpense" :min="0" />
          </a-form-item>
          <a-form-item label="交通費">
            <a-input-number v-model:value="transportExpense" :min="0" />
          </a-form-item>
          <a-form-item label="その他">
            <a-input-number v-model:value="other_expenses" :min="0" />
          </a-form-item>
          <a-form-item label="その他の説明">
            <a-input v-model:value="description" :min="0" />
          </a-form-item>
          <!-- 提交按钮 -->
          <a-form-item>
            <a-button type="primary" @click="submitAttendance">チェックイン</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-layout-content>
  </a-layout>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios from '@/api/axios';
import { message } from 'ant-design-vue';
import dayjs from 'dayjs';

const store = useStore();
const selectedLocation = ref(null);
const selectTime = ref(null);
const attendanceStatus = ref('1天');
const drinkExpense = ref(300);
const transportExpense = ref(0);
const other_expenses = ref(0);
const isSubmitting = ref(false);
const description = ref();

const baseDailyWage = computed(() => store.state.userInfo.daily_pay);

const dailyWage = computed(() => {
  switch (attendanceStatus.value) {
    case '1天':
      return baseDailyWage.value;
    case '半天':
      return baseDailyWage.value * 0.5;
    case 'leave':
    case 'none':
      return 0;
    default:
      return baseDailyWage.value; // 默认返回全日工资
  }
});

const loadLocations = async () => {
  if (store.state.locations.length === 0) {
    try {
      const response = await axios.get('/api/locations/?is_completed=false');
      store.commit('setLocations', response.data);
    } catch (error) {
      message.error('位置情報の取得に失敗しました。');
    }
  }
};

onMounted(loadLocations);

const locationOptions = computed(() => store.state.locations.map(location => ({
  label: location.name,
  value: location.id
})));


const disabledDate = current => {
  // 获取今天、前天和大前天的日期
  const today = dayjs();
  const yesterday = dayjs().subtract(1, 'day');
  const dayBeforeYesterday = dayjs().subtract(2, 'day');

  // 如果当前日期不是这三天中的一个，则禁用
  return !(
      current.isSame(today, 'day') ||
      current.isSame(yesterday, 'day') ||
      current.isSame(dayBeforeYesterday, 'day')
  );
};


const submitAttendance = async () => {
  if (!selectTime.value || !selectedLocation.value) {
    message.error('必要なフィールドをすべて入力してください。');
    return;
  }
  isSubmitting.value = true;
  try {
    const checkUrl = `/api/check-attendance/check-attendance/?employee_id=${store.state.userInfo.id}&date=${selectTime.value.format('YYYY-MM-DD')}&location_id=${selectedLocation.value}`;
    const checkResponse = await axios.get(checkUrl);
    const { exists, id } = checkResponse.data;
    let apiUrl = '/api/attendances/';
    let method = 'post';
    let data = {
      employee: store.state.userInfo.username,
      location: selectedLocation.value,
      date: selectTime.value.format('YYYY-MM-DD'),
      attendance_status: attendanceStatus.value,
      drink_expense: drinkExpense.value,
      transport_expense: transportExpense.value,
      daily_wage: dailyWage.value,
      other_expenses: other_expenses.value,
      description: description.value
    };
    if (exists) {
      method = 'put';
      apiUrl += `${id}/`;
    }
    await axios({ method, url: apiUrl, data });
    message.success('チェックインが成功しました。');
  } catch (error) {
    message.error('チェックインに失敗しました。もう一度試してください。');
  } finally {
    isSubmitting.value = false;
  }
};
</script>


<style scoped>
.mobile-check-in-layout {
  max-width: 480px; /* 限制最大宽度以适应移动设备 */
  margin: auto;
}

.mobile-check-in-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1890ff;
  color: white;
  padding: 10px;
}


</style>
