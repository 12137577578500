<template>
  <a-layout class="mobile-user-info-layout">
    <a-layout-header class="mobile-user-info-header">
      <div class="header-content">
        <h1>ユーザー情報</h1>
      </div>
    </a-layout-header>
    <a-layout-content style="padding: 24px;">
      <a-row :gutter="24">
        <a-col :span="24" :md="12">
          <div class="info-item">
            <strong>ユーザー名:</strong> {{ userInfo.username }}
          </div>
        </a-col>
        <a-col :span="24" :md="12">
          <div class="info-item">
            <strong>メール:</strong> {{ userInfo.email }}
          </div>
        </a-col>
        <a-col :span="24" :md="12">
          <div class="info-item">
            <strong>名前:</strong> {{ userInfo.fullName }}
          </div>
        </a-col>
        <a-col :span="24" :md="12">
          <div class="info-item">
            <strong>電話番号:</strong> {{ userInfo.phone }}
          </div>
        </a-col>
        <a-col :span="24" :md="12">
          <div class="info-item">
            <strong>役割:</strong> {{ userInfo.role }}
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="48">
        <a-col :span="12">
          <a-button type="primary" class="logout-button" @click="handleLogout" style="width: 100%;">ログアウト</a-button>
        </a-col>
        <a-col :span="12">
          <a-button type="primary" @click="showChangePasswordModal" style="width: 100%;">パスワード変更</a-button>
        </a-col>
      </a-row>
    </a-layout-content>
  </a-layout>
  <a-modal
      v-model:open="isModalVisible"
      title="パスワード変更"
      ok-text="変更"
      cancel-text="キャンセル"
      @ok="handleChangePassword"
  >
    <a-form
        ref="passwordForm"
        :model="passwordForm"
        @submit.prevent="handleChangePassword"
    >
      <a-form-item
          label="旧パスワード"
          :rules="[{
          required: true,
          message: '旧パスワードを入力してください。'
        }]"
      >
        <a-input-password v-model:value="passwordForm.oldPassword" />
      </a-form-item>
      <a-form-item
          label="新パスワード"
          :rules="[{
          required: true,
          message: '新パスワードを入力してください。'
        }]"
      >
        <a-input-password v-model:value="passwordForm.newPassword" />
      </a-form-item>
      <a-form-item
          label="新パスワード確認"
          :rules="[{
          required: true,
          message: '新パスワードを再度入力してください。'
        }]"
      >
        <a-input-password v-model:value="passwordForm.confirmPassword" />
      </a-form-item>
      <p v-if="passwordMismatch" style="color: red;">二つの新パスワードが一致しません。</p>
    </a-form>
  </a-modal>
</template>



<script setup>
import { useRouter } from 'vue-router';
import { computed, ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import axios from "@/api/axios";
import { message } from 'ant-design-vue';

const router = useRouter();
const store = useStore();

const oldPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');
const isModalVisible = ref(false);

const passwordForm = ref({
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
});

const passwordMismatch = computed(() => {
  return passwordForm.value.newPassword !== passwordForm.value.confirmPassword;
});

const showChangePasswordModal = () => {
  console.log('showChangePasswordModal is triggered');
  isModalVisible.value = true;
  console.log(isModalVisible.value);
  nextTick(() => {
    newPassword.value = '';
    confirmPassword.value = '';
    oldPassword.value = '';
  });
};

const handleLogout = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      await axios.post('/auth/logout/', { refresh_token: refreshToken });
    }
  } catch (error) {
    console.error('Logout error:', error);
  } finally {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    store.commit('clearUserInfo');
    await router.push('/login');
  }
};

const handleChangePassword = async () => {
  const oldPass = passwordForm.value.oldPassword || '';
  const newPass = passwordForm.value.newPassword || '';
  const confirmPass = passwordForm.value.confirmPassword || '';
  if (!oldPass.trim() || !newPass.trim() || newPass !== confirmPass) {
    message.error('入力された情報を確認してください。');
    return;
  }
  try {
    const response = await axios.post('/auth/change-password/ ', {
      old_password: oldPass,
      new_password: newPass,
    });

    if (response.data.success) {
      message.success('パスワードが正常に更新されました。');
      isModalVisible.value = false;
      // 清空表单
      passwordForm.value.oldPassword = '';
      passwordForm.value.newPassword = '';
      passwordForm.value.confirmPassword = '';
    } else {
      message.error(response.data.error || 'パスワードの更新に失敗しました。');
    }
  } catch (error) {
    message.error('パスワードの更新中にエラーが発生しました。');
    console.error('Password change error:', error);
  }
};

const userInfo = computed(() => {
  return {
    username: store.state.userInfo?.username,
    email: store.state.userInfo?.email,
    fullName: `${store.state.userInfo?.last_name} ${store.state.userInfo?.first_name}`,
    phone: store.state.userInfo?.phone,
    role: store.state.userInfo?.role
  };
});
</script>


<style scoped>
.mobile-user-info-layout {
  max-width: 480px;
  margin: auto;
}

.mobile-user-info-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1890ff;
  color: white;
  padding: 10px; /* 减小内边距 */
}

.info-item {
  margin-bottom: 16px; /* 增加底部外边距 */
  background: #f0f2f5; /* 背景色 */
  padding: 12px; /* 内边距 */
  border-radius: 4px; /* 圆角 */
}

.header-content {
  text-align: center;
  font-size: 18px; /* 调整标题字体大小 */
}

a-descriptions {
  margin-top: 20px; /* 增加顶部外边距 */
}

a-descriptions-item {
  padding: 8px 0; /* 减小上下内边距 */
}

.mobile-user-info-layout {
  max-width: 480px;
  margin: auto;
}
.mobile-user-info-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1890ff;
  color: white;
  padding: 10px;
}

@media (max-width: 480px) {
  .info-item {
    font-size: 14px; /* 在小屏幕上减小字体大小 */
  }
}
</style>

