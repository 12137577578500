import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import LoginForm from '@/views/LoginForm.vue';
import DashboardPage from '@/views/DashboardPage.vue';
import AttendanceRecordsView  from '@/views/AttendanceRecordsView.vue';
import Profile from '@/views/UserProfile.vue';
import userCreate from '@/views/userCreate.vue';
import MobileDashboard from '@/views/MobileDashboard.vue';
import MobileCheckIn from '@/views/MobileCheckIn.vue';
import MobileUserInfo from '@/views/MobileUserInfo.vue';
import MobileAttendanceList from '@/views/MobileAttendanceList.vue';
import MobileLocations from '@/views/MobileLocations.vue';
import CheckinPage from '@/views/CheckInPage.vue'
import LocationAttendance from '@/views/LocationAttendanceView.vue'
import DesktopLocation from "@/views/DesktopLocation.vue";
import MaterialRegistration from "@/views/MaterialRegistration.vue";
import MobileMaterialRegistration from "@/views/MobileMaterialRegistration.vue";

import axios from '@/api/axios';
import { checkDeviceType } from '@/UserTools';

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginForm
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: DashboardPage,
        meta: { requiresAuth: true },
        children: [
            { path: 'attendance-records', component: AttendanceRecordsView },
            { path: 'profile', component: Profile },
            { path: 'user-create', component: userCreate },
            { path: 'checkin-page', component: CheckinPage },
            { path: 'desktop-location', component: DesktopLocation },
            { path: 'desktop-location-attendance', component: LocationAttendance },
            { path: 'material-registration', component: MaterialRegistration },
        ]
    },
    {
        path: '/mobile-dashboard',
        name: 'MobileDashboard',
        component: MobileDashboard,
        meta: { requiresAuth: true },
        children: [
            { path: 'check-in', component: MobileCheckIn },
            { path: 'user-info', component: MobileUserInfo },
            { path: 'attendance-list', component: MobileAttendanceList },
            { path: 'site-management', component: MobileLocations },
            { path: 'location-attendance', component: LocationAttendance },
            { path: 'material-registration', component: MobileMaterialRegistration },
        ]
    },
    {
        path: '/',
        redirect: '/login'
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    const token = localStorage.getItem('token');
    const deviceType = checkDeviceType();
    let firstPage = deviceType === 'mobile' ? '/mobile-dashboard/check-in' : '/dashboard/checkin-page/';

    if (to.path === '/login' && token) {
        return next({ path: firstPage }); // 直接返回结束函数执行
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!token) {
            alert('ログインして続行してください。');
            return next('/login');
        }

        if (store.getters.isAuthenticated) {
            return next();
        }

        try {
            const response = await axios.get('/auth/profile/');
            store.commit('setUserInfo', response.data);
            return next();
        } catch (error) {
            alert('セッションが期限切れです。もう一度ログインしてください。');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            store.commit('clearUserInfo');
            return next('/login');
        }
    }

    next(); // 对于不需要验证的路由，正常进行
    document.title = 'NKG勤怠';
});







export default router;
