<template>
  <a-layout>
    <a-layout-content class="layout-content">
      <a-row class="a-row">
        <a-button type="primary" @click="exportToExcel" class="a-button">导出Excel</a-button>
        <a-col :span="24" class="a-col">
          <a-button type="primary" @click="showModal" class="a-button">材料登録</a-button>
        </a-col>
        <a-col :span="24" class="a-col">
          <VueDatePicker v-model="selectedMonth"
                         format="yyyy/MM"
                         locale="ja"
                         month-picker
                         auto-apply
                         @input="fetchRecords"
                         style="width: 100%;" />
        </a-col>
        <a-col :span="24" class="a-col">
          <a-button type="primary" @click="fetchRecords" class="a-button">搜索</a-button>
        </a-col>
        <a-col :span="24" class="a-col">
          <a-select v-model:value="searchField" style="width: 100%; margin-top: 8px;" @change="filterRecords" class="select-input">
            <a-select-option value="location_name">場所名</a-select-option>
            <a-select-option value="registrant_name">登記人</a-select-option>
          </a-select>
          <a-input-search
              placeholder="場所名または登録者で検索"
              @search="searchText"
              style="width: 100%;" />
        </a-col>
        <a-col :span="24" class="a-col margin-bottom">
          <a-select v-model:value="sortKey" @change="sortRecords" style="width: 100%;">
            <a-select-option value="date">日付順序</a-select-option>
            <a-select-option value="product_name">材料名順序</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-modal
          title="材料登録"
          v-model:open="visible"
          @ok="handleSubmit"
          @cancel="handleCancel"
          class="registration-modal">
        <a-form :model="newRecord">
          <a-form-item label="日付">
            <VueDatePicker v-model="newRecord.date"
                           format="yyyy/MM/dd"
                           locale="ja"
                           model-type="yyyy-MM-dd"
                           week-start="0"
                           auto-apply
                           disable-month-year-select
                           hide-offset-dates
                           style="width: 100%;" />
          </a-form-item>
          <a-form-item label="商品名">
            <a-input v-model:value="newRecord.product_name" />
          </a-form-item>
          <a-form-item label="容量単位">
            <a-input v-model:value="newRecord.unit" />
          </a-form-item>
          <a-form-item label="数量">
            <a-input-number v-model:value="newRecord.quantity" style="width: 100%;" />
          </a-form-item>
          <a-form-item label="説明">
            <a-textarea v-model:value="newRecord.description" rows="4" />
          </a-form-item>
          <a-form-item label="担当者">
            <a-input v-model:value="newRecord.responsible" />
          </a-form-item>
          <a-form-item label="现场名">
            <a-select v-model:value="newRecord.location" style="width: 100%;">
              <a-select-option v-for="option in locationOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
      <div class="card-container">
        <a-card v-for="item in filteredRecords" :key="item.id" :title="item.location_name" class="record-card">
          <p>日付: {{ item.date }}</p>
          <p>登録材料: {{ item.product_name }}</p>
          <p>容量単位: {{ item.unit }}</p>
          <p>数量: {{ item.quantity }}</p>
          <p>説明: {{ item.description }}</p>
          <p>担当者: {{ item.responsible }}</p>
          <p>登记人: {{ item.registrant_name }}</p>
          <template #extra>
            <a-button type="primary" @click="editRecord(item)">修正</a-button>
            <a-button type="default" @click="deleteRecord(item.id)">削除</a-button>
          </template>
        </a-card>
      </div>
    </a-layout-content>
  </a-layout>
</template>



<script setup>
import { ref, onMounted, computed } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import axios from '@/api/axios';
import { useStore } from 'vuex';
import * as XLSX from 'xlsx';

const selectedMonth = ref(new Date());  // 当前月份作为默认值
const store = useStore();
const locationOptions = computed(() => store.state.locations.map(location => ({
  label: location.name,
  value: location.id
})));

const filteredRecords = ref([]);  // 过滤后的记录
const searchQuery = ref('');  // 搜索文本的响应式引用
const searchField = ref('location_name'); // 默认搜索字段

const records = ref([]);
const newRecord = ref({
  date: '',
  product_name: '',
  unit: '',
  quantity: null,
  description: '',
  responsible: "",
  location: null,
  registrant: store.state.userInfo.id
});
const visible = ref(false);

function searchText(value) {
  searchQuery.value = value;
  filterRecords();
}



const sortKey = ref('date');  // 默认排序方式

function sortRecords() {
  filteredRecords.value.sort((a, b) => {
    if (sortKey.value === 'date') {
      // 按日期排序
      return new Date(a.date) - new Date(b.date);
    } else if (sortKey.value === 'product_name') {
      // 按材料名称的首字母排序（考虑到日文、中文和英文）
      return a.product_name.localeCompare(b.product_name, 'ja');
    }
  });
}



const today = new Date().toISOString().split('T')[0];
newRecord.value.date = today;

const formatDateForAPI = (dateValue) => {
  let year, month;

  // 检查dateValue是否是日期对象
  if (dateValue instanceof Date) {
    year = dateValue.getFullYear();
    month = dateValue.getMonth() + 1;  // 获取月份，JS中月份是从0开始的
  } else {
    // 处理来自VueDatePicker的对象
    year = dateValue.year;
    month = dateValue.month + 1;  // 假设从VueDatePicker传递的月份也是从0开始的
  }

  return { year, month };
};

const fetchRecords = async () => {
  try {
    const { year, month } = formatDateForAPI(selectedMonth.value);
    const response = await axios.get(`/material/material-registration/?year=${year}&month=${month}`);
    records.value = response.data;
    filterRecords();  // 更新过滤
    sortRecords();  // 更新排序
  } catch (error) {
    console.error('データの取得に失敗しました', error);
  }
};

function filterRecords() {
  filteredRecords.value = records.value.filter(item => {
    if (searchField.value === 'location_name') {
      return item.location_name.includes(searchQuery.value);
    } else if (searchField.value === 'registrant_name') {
      return item.registrant_name.includes(searchQuery.value);
    }
    return false;
  });
  sortRecords();  // 确保过滤后也应用排序
}



onMounted(fetchRecords);

const showModal = () => {
  visible.value = true;
};

const handleSubmit = async () => {
  const method = newRecord.value.id ? 'patch' : 'post';
  const url = newRecord.value.id ?
      `/material/material-registration/${newRecord.value.id}/` :
      '/material/material-registration/';
  try {
    const response = await axios[method](url, newRecord.value);
    if (response.data) {
      if (method === 'post') {
        records.value.push(response.data); // 直接使用返回的数据添加新记录
      } else {
        const index = records.value.findIndex(item => item.id === response.data.id);
        if (index !== -1) {
          records.value[index] = response.data; // 更新本地记录为返回的更新后的数据
        }
      }
    } else {
      await fetchRecords(); // 如果没有数据返回，重新拉取最新的数据列表
    }
    visible.value = false;
  } catch (error) {
    console.error('操作失败', error);
  }
};

const deleteRecord = async (id) => {
  try {
    const response = await axios.delete(`/material/material-registration/${id}/`);
    if (response.status === 200 || response.status === 204) {
      await fetchRecords();
    } else {
      await fetchRecords(); // 如果删除不成功，重新拉取数据
    }
  } catch (error) {
    console.error('削除に失敗しました', error);
    await fetchRecords(); // 确保本地数据同步
  }
};


const handleCancel = () => {
  visible.value = false;
};

const editRecord = (item) => {
  newRecord.value = { ...item };
  showModal();
};

const exportToExcel = () => {
  const wb = XLSX.utils.book_new(); // 创建一个新的工作簿

  // 获取月份并格式化为 yyyy-MM 格式
  const selectedDate = selectedMonth.value;
  const formattedMonth = `${selectedDate.getFullYear()}-${('0' + (selectedDate.getMonth() + 1)).slice(-2)}`;

  // 按 location_name 分类记录
  const groupedRecords = filteredRecords.value.reduce((acc, item) => {
    acc[item.location_name] = acc[item.location_name] || [];
    acc[item.location_name].push(item);
    return acc;
  }, {});

  // 为每个分类创建一个工作表
  Object.entries(groupedRecords).forEach(([locationName, records]) => {
    const ws = XLSX.utils.json_to_sheet(records.map(record => ({
      '日付': record.date,
      '登録材料': record.product_name,
      '容量単位': record.unit,
      '数量': record.quantity,
      '説明': record.description,
      '担当者': record.responsible,
      '登记人': record.registrant_name
    })), { header: ['日付', '登録材料', '容量単位', '数量', '説明', '担当者', '登记人'] });
    XLSX.utils.book_append_sheet(wb, ws, locationName); // 将工作表添加到工作簿
  });

  // 生成带有月份的 Excel 文件名
  XLSX.writeFile(wb, `登録材料-${formattedMonth}.xlsx`);
};

</script>

<style scoped>
.layout-content {
  padding: 12px;  /* 减少内边距适应小屏幕 */
}

.a-row {
  display: block;  /* 改为块级布局，每项占满一行 */
}

.a-col {
  padding-bottom: 5px;  /* 增加底部间隔 */
}

.select-input, .a-button, .a-input {
  width: 100%;
  margin-bottom: 5px;
}

.card-container {
  display: block;  /* 卡片单列显示 */
}

.record-card {
  width: 100%;  /* 卡片宽度调整 */
}

.registration-modal .ant-modal-body {
  padding-top: 12px;  /* 减少内边距 */
}

.margin-bottom {
  margin-bottom: 10px; /* 新添加的底部间距 */
}
</style>

