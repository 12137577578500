<template>
  <div id="login-form-container">
    <div class="title-container">
      <h1>株式会社NKG</h1>
      <h2>勤怠管理システム</h2>
    </div>
    <a-form @submit.prevent="handleSubmit" class="login-form">
      <a-form-item label="ユーザー名">
        <a-input v-model:value="loginForm.username" placeholder="ユーザー名を入力してください" />
      </a-form-item>
      <a-form-item label="パスワード">
        <a-input
            type="password"
            v-model:value="loginForm.password"
            placeholder="パスワードを入力してください"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" block>ログイン</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from '@/api/axios';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { checkDeviceType } from '@/UserTools';

export default {
  name: 'LoginForm',
  setup() {
    const loginForm = ref({
      username: '',
      password: '',
    });

    const router = useRouter();
    const store = useStore();
    const deviceType = checkDeviceType();



    onMounted(() => {
      const token = localStorage.getItem('token');
      if (token) {
        router.push('/dashboard');
      }
    });


    const handleSubmit = async () => {
      if (!loginForm.value.username || !loginForm.value.password) {
        message.error('ユーザー名とパスワードを入力してください');
        return;
      }
      const url = '/auth/login/';
      try {
        const response = await axios.post(url, loginForm.value, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const { access, refresh } = response.data; // 获取access和refresh令牌
        if (typeof access !== 'string' || typeof refresh !== 'string') {
          throw new Error('Invalid token format');
        }
        localStorage.setItem('token', access);
        localStorage.setItem('refreshToken', refresh);

        // 获取用户详细信息
        try {
          const profileResponse = await axios.get('/auth/profile/', {
            headers: {
              Authorization: `Bearer ${access}`
            }
          });
          store.commit('setUserInfo', profileResponse.data);
        } catch (error) {
          message.error('无法获取用户信息，请重试。');
        }

        message.success('ログイン成功');
        const targetRoute = deviceType === 'mobile' ? '/mobile-dashboard/check-in' : '/dashboard/checkin-page';
        await router.push(targetRoute); // 跳转到Dashboard页面
      } catch (error) {
        if (error.response) {
          message.error('错误响应:', error.response.data);
        }
        message.error('ログインに失敗しました。ユーザー名とパスワードを確認してください。');
      }
    };

    return {
      loginForm,
      handleSubmit,
    };
  }
};
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

#login-form-container {
  background-image: url('../imageFlie/login-5.jpg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

@media only screen and (max-width: 768px) {
  #login-form-container {
    background-size: cover;
  }
}

.login-form {
  width: 100%;
  max-width: 300px;
}

.title-container h1, .title-container h2 {
  text-align: center;
  margin-bottom: 24px;
}
</style>
