<template>
  <a-layout class="location-layout">
    <a-layout-header class="location-header">
      <div class="header-content">
        <h1>現場管理</h1>
      </div>
    </a-layout-header>
    <a-layout-content style="padding: 24px;">
      <a-row :gutter="16" class="filter-row">
        <a-col :span="6">
          <a-select v-model:value="selectedField" placeholder="フィールドを選択">
            <a-select-option value="name">現場名</a-select-option>
            <a-select-option value="address">地址</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="10">
          <a-input-search
              placeholder="検索内容を入力"
              enter-button="検索"
              v-model:value.lazy="searchQuery"
              @search="handleSearch"
          />
        </a-col>
        <a-col :span="6">
          <a-select v-model:value="completedFilter" placeholder="完了状態を選択">
            <a-select-option value="all">すべて</a-select-option>
            <a-select-option value="true">完了</a-select-option>
            <a-select-option value="false">未完了</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-button v-if="isManager" type="primary" class="add-button" @click="showModalForNew">新增</a-button>
      <a-modal
          v-model:open="showModal"
          title="新規現場の追加"
          @ok="handleSubmit"
          @cancel="() => { showModal.value = false; }"
          closeable
          :mask-closable="true"
      >
        <a-form
            :model="form"
            ref="formRef"
            :rules="rules"
        >
          <a-form-item label="現場名" name="name">
            <a-input v-model:value="form.name" placeholder="現場名を入力してください"/>
          </a-form-item>
          <a-form-item label="地址" name="address">
            <a-input v-model:value="form.address" placeholder="地址を入力してください"/>
          </a-form-item>
          <a-form-item label="管理者" name="supervisors">
            <a-select
                v-model:value="selectedManagers"
                mode="multiple"
                allowClear
                placeholder="管理者を選択"
                @change="handleManagerChange"
            >
              <a-select-option
                  v-for="option in managerOptions"
                  :key="option.label"
                  :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="工期" name="date_range">
            <VueDatePicker v-model="dateRange" range />
          </a-form-item>
          <a-form-item label="工種" name="work_type">
            <a-input v-model:value="form.work_type" placeholder="工種を入力してください"/>
          </a-form-item>
          <a-form-item label="実行会社" name="executing_company">
            <a-input v-model:value="form.executing_company" placeholder="実行会社を入力してください"/>
          </a-form-item>
          <a-form-item label="契約金額" name="contract_amount">
            <a-input-number v-model:value="form.contract_amount" style="width: 100%" :min="0" />
          </a-form-item>
          <a-form-item label="外注金額" name="subcontract_amount">
            <a-input-number v-model:value="form.subcontract_amount" style="width: 100%" :min="0" />
          </a-form-item>
          <a-form-item label="是否完工" name="is_completed"> <!-- 是否完工 -->
            <a-select v-model:value="form.is_completed">
              <a-select-option value="true">已完工</a-select-option> <!-- 已完工 -->
              <a-select-option value="false">未完工</a-select-option> <!-- 未完工 -->
            </a-select>
          </a-form-item>
          <!-- 月度成本输入区域 -->
          <div v-for="(cost, index) in form.monthly_costs" :key="index" class="monthly-cost-item">
            <a-form-item label="月を選択してください">
<!--              <div>Month: {{ cost.month }}</div> &lt;!&ndash; 使用插值表达式打印 &ndash;&gt;-->
              <VueDatePicker v-model="cost.month" locale="ja" input-id="'month_input_' + index" month-picker/>
            </a-form-item>
            <a-form-item label="材料代">
              <a-input-number v-model:value="cost.material_cost" style="width: 100%" :min="0" placeholder="材料代" />
            </a-form-item>
            <a-form-item label="副資材代">
              <a-input-number v-model:value="cost.subsidiary_material_cost" style="width: 100%" :min="0" placeholder="副資材代" />
            </a-form-item>
            <a-button @click="removeMonthlyCost(index)">删除</a-button>
          </div>
          <a-button @click="addMonthlyCost">月度成本を追加</a-button>
        </a-form>
      </a-modal>
      <a-list
          :dataSource="filteredLocationData"
          :loading="loading"
          itemLayout="horizontal"
          class="location-list"
      >
        <a-collapse v-model:activeKey="activeKey" accordion>
          <a-collapse-panel
              v-for="item in locationData"
              :key="item.id"
              :header="`現場名: ${item.name}`"
          >
            <a-row :gutter="16" align="middle" justify="center">
              <a-col :span="24">
                <div>地址: {{ item.address }}</div>
                <div>管理者: {{ item.supervisors.join(', ') }}</div>
                <div>工期開始时间: {{ item.start_date }}</div>
                <div>工期終了时间: {{ item.end_date }}</div>
                <div>工種: {{ item.work_type }}</div>
                <div>実行会社: {{ item.executing_company }}</div>
                <div>契約金額: {{ formatCurrency(item.contract_amount) }}</div>
                <div>外注金額: {{ formatCurrency(item.subcontract_amount) }}</div>
                <!-- 显示总成本 -->
                <div>材料代总额: {{ formatCurrency(item.total_material_cost) }}</div>
                <div>副資材代总额: {{ formatCurrency(item.total_subsidiary_material_cost) }}</div>
                <div>是否完工: {{ item.is_completed ? '已完工' : '未完工' }}</div>
              </a-col>
              <div class="button-container">
                <a-space>
                  <a-button v-if="isManager" type="primary" @click="editRecord(item)">修正</a-button>
                  <a-button v-if="isManager" type="default" @click="deleteRecord(item.id)">削除</a-button>
                </a-space>
              </div>
            </a-row>
          </a-collapse-panel>
        </a-collapse>
      </a-list>
    </a-layout-content>
  </a-layout>
</template>

<script setup>
import {ref, computed, onMounted } from 'vue';
import axios from '@/api/axios';
import {useStore} from 'vuex';
import {Modal, message} from 'ant-design-vue';

const activeKey = ref([]);
const store = useStore();
const loading = ref(false);
const searchQuery = ref('');
const selectedField = ref('name');
const locationData = ref([]);
const managerOptions = ref([]); // 管理者选项数据
const selectedManagers = ref([]);
const formRef = ref(null);
const dateRange = ref([]);
const completedFilter = ref('all');
const isManager = computed(() => store.state.userInfo.role === 'manager');

const handleManagerChange = (value) => {
  selectedManagers.value = value;
  formRef.value.validateField('supervisors');
};

const showModal = ref(false);
const form = ref({
  id: null,
  name: '',
  address: '',
  start_date: '',
  end_date: '',
  work_type: '',
  executing_company: '',
  contract_amount: 0,
  subcontract_amount: 0,
  monthly_costs: [],
  is_completed: ""
});

const showModalForNew = () => {
  showModal.value = true;
  const today = new Date();
  const threeMonthsLater = new Date(today.setMonth(today.getMonth() + 3));
  form.value = {
    id: null,
    name: '',
    address: '',
    start_date: today.toISOString().split('T')[0],
    end_date: threeMonthsLater.toISOString().split('T')[0],
    work_type: '',
    executing_company: '',
    contract_amount: 0,
    subcontract_amount: 0,
    monthly_costs: [],
    is_completed: "false"
  };
  selectedManagers.value = [];
  dateRange.value = [form.value.start_date, form.value.end_date]; // 初始化 dateRange
};

const addMonthlyCost = () => {
  if (!form.value.monthly_costs) {
    form.value.monthly_costs = [];
  }
  form.value.monthly_costs.push({
    month: '',
    material_cost: 0,
    subsidiary_material_cost: 0
  });
};



// 动态删除月度成本，使用 id
const removeMonthlyCost = async (index) => {
  const costId = form.value.monthly_costs[index].id;
  if (costId) {
    await axios.delete(`/api/monthly_costs/${costId}/`); // 发送删除请求
    form.value.monthly_costs.splice(index, 1); // 更新本地状态
  } else {
    form.value.monthly_costs.splice(index, 1); // 直接从表单移除新添加的项
  }
};


// 将 {month: {month: X, year: Y}} 转换为 YYYY-MM-DD
function convertMonthYearToDate(monthYear) {
  if (!monthYear || !monthYear.month || !monthYear.year) {
    console.log("Invalid date input: ", monthYear);
    return '1900-01-01';  // 使用默认值或抛出错误
  }

  const formattedDate = `${monthYear.year}-${String(monthYear.month + 1).padStart(2, '0')}-01`;
  console.log("Formatted date: ", formattedDate);
  return formattedDate;
}


const handleSubmit = async () => {
  form.value.start_date = new Date(form.value.start_date).toISOString();
  form.value.end_date = new Date(form.value.end_date).toISOString();

  // 格式化 monthly_costs 中的 month 字段为 YYYY-MM-DD
  form.value.monthly_costs = form.value.monthly_costs.map(cost => ({
    id: cost.id,
    location: form.value.id,
    month: convertMonthYearToDate(cost.month) ,
    material_cost: cost.material_cost,
    subsidiary_material_cost: cost.subsidiary_material_cost
  }));


  const apiUrl = form.value.id ? `/api/locations/${form.value.id}/` : '/api/locations/';
  const method = form.value.id ? 'patch' : 'post';

  try {
    const response = await axios[method](apiUrl, {
      ...form.value,
      supervisors: selectedManagers.value
    });
    if (response.status === 200 || response.status === 201) {
      message.success(`現場が${form.value.id ? '更新' : '追加'}されました。`);
      showModal.value = false;
      await handleSearch();
    } else {
      message.error('応答データがありません。');
    }
  } catch (error) {
    message.error(`現場の${form.value.id ? '更新' : '追加'}に失敗しました。` + error.message);
    showModal.value = false;
  }
};


const handleSearch = async () => {
  loading.value = true;
  try {
    let searchParams = new URLSearchParams();

    // 如果用户选择了完工状态，添加到搜索参数中
    if (completedFilter.value !== 'all') {
      searchParams.append('is_completed', completedFilter.value);
    }

    // 如果用户在搜索框中输入了内容，根据选择的字段添加到搜索参数中
    if (searchQuery.value) {
      searchParams.append(selectedField.value, searchQuery.value);
    }

    // 构建完整的API请求URL
    const apiUrl = `/api/locations/?${searchParams.toString()}`;
    const response = await axios.get(apiUrl);

    // 处理响应数据
    locationData.value = response.data.map(item => ({
      ...item,
      monthly_costs: item.monthly_costs.map(cost => {
        // 使用修改后的函数转换日期
        const formattedMonth = convertDateToMonthYear(cost.month);
        console.log("转换后的月份：", formattedMonth);
        return {
          ...cost,
          month: formattedMonth // 将日期字符串转换为 VueDatePicker 的月份选择器可以处理的对象格式
        };
      })
    }));

    loading.value = false;
  } catch (error) {
    message.error('検索リクエストに失敗しました。');
    loading.value = false;
  }
};



function convertDateToMonthYear(dateStr) {
  console.log("转换输入日期：", dateStr); // 打印转换前的日期
  const parts = dateStr.split('-');
  if (parts.length === 3) {
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    if (!isNaN(year) && !isNaN(month)) {
      return {
        month: month -1 ,
        year: year
      };
    }
  }
  console.error("无效的日期格式：", dateStr);
  return {
    month: new Date().getMonth() , // 默认为当前月份
    year: new Date().getFullYear() // 默认为当前年份
  };
}


const deleteRecord = async (id) => {
  Modal.confirm({
    title: '確認',
    content: 'このレコードを削除してもよろしいですか？',
    okText: '削除',
    okType: 'danger',
    cancelText: 'キャンセル',
    onOk: async () => {
      try {
        loading.value = true;
        await axios.delete(`/api/locations/${id}/`);
        message.success('削除成功');
        await handleSearch();
      } catch (error) {
        message.error('削除に失敗しました。');
      } finally {
        loading.value = false;
      }
    }
  });
};

// 示范如何在加载数据时包含 id
const editRecord = (item) => {
  selectedManagers.value = item.supervisors;

  form.value = {
    id: item.id,
    name: item.name,
    address: item.address,
    start_date: item.start_date,
    end_date: item.end_date,
    work_type: item.work_type,
    executing_company: item.executing_company,
    contract_amount: item.contract_amount,
    subcontract_amount: item.subcontract_amount,
    monthly_costs: item.monthly_costs.map(cost => {
      // 验证后端数据是否包含有效的year和month
      if (cost.month && cost.month.year && cost.month.month) {
        return {
          id: cost.id, // 保持月度成本的 id
          month: {
            year: cost.month.year,
            month: cost.month.month
          },
          material_cost: cost.material_cost,
          subsidiary_material_cost: cost.subsidiary_material_cost
        };
      } else {
        console.error("无效的月份数据：", cost.month);
        return {
          ...cost,
          month: { // 如果数据无效，使用默认值
            year: new Date().getFullYear(),
            month: new Date().getMonth()
          }
        };
      }
    })
  };
  dateRange.value = [form.value.start_date, form.value.end_date];
  showModal.value = true;
};



const fetchManagers = async () => {
  try {
    const response = await axios.get('/auth/managerlist/');
    managerOptions.value = response.data.map(manager => ({
      label: `${manager.last_name} ${manager.first_name}`, // 确保你的数据中有 last_name 和 first_name 字段
      value: manager.username // 确保你的数据中有 username 字段
    }));
  } catch (error) {
    message.error('管理者データの取得に失敗しました。');
  }
};

function formatCurrency(value) {
  const numberFormat = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY'
  });
  return numberFormat.format(parseFloat(value));
}

const rules = {
  name: [
    {required: true, message: '現場名を入力してください', trigger: 'blur'}
  ],
  address: [
    {required: true, message: '地址を入力してください', trigger: 'blur'}
  ],
  supervisors: [
    {required: true, message: '管理者を選択してください', type: 'array', min: 1, trigger: 'change'}
  ],
  start_date: [
    {required: false, trigger: 'change'} // 修改为非必填
  ],
  end_date: [
    {required: false, trigger: 'change'} // 修改为非必填
  ],
  work_type: [
    {required: true, message: '工種を入力してください', trigger: 'blur'}
  ],
};

const filteredLocationData = computed(() => {
  if (completedFilter.value === 'all') {
    return locationData.value;
  }
  return locationData.value.filter(item => {
    const isCompleted = item.is_completed ? 'true' : 'false';
    return isCompleted === completedFilter.value;
  });
});

onMounted(fetchManagers);
onMounted(handleSearch);
</script>

<style scoped>
.location-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1890ff;
  color: white;
  padding: 10px;
}

.header-content {
  text-align: center;
}

.filter-row {
  margin-bottom: 20px;
}

.add-button {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-control-input-content > .a-input,
.ant-form-item-has-error .ant-form-item-control-input-content > .a-select-selector {
  border-color: red !important;
  color: red; /* 更改错误信息的颜色 */
}

</style>
