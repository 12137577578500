<template>
  <a-layout class="layout">
    <a-page-header class="header" title="現場の勤務情報" />
    <a-row :gutter="16" class="filter-row">
      <a-col :span="6">
        <a-select v-model:value="locationStatus" style="width: 100%">
          <a-select-option value="all">全部</a-select-option>
          <a-select-option value="completed">已完成</a-select-option>
          <a-select-option value="not_completed">未完成</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="10">
        <a-month-picker
            class="month-picker"
            v-model="selectedMonth"
            @change="handleMonthChange"
            placeholder="月を選択"
            style="width: 100%;" />
      </a-col>
      <a-col :span="8">
        <a-button :loading="loading" @click="exportToExcel" type="primary" style="width: 100%;">ダウンロードEXCEL</a-button>
      </a-col>
    </a-row>

    <a-layout-content class="layout-content">
      <a-collapse accordion>
        <a-collapse-panel
            v-for="location in formattedLocations"
            :key="location.name"
            :header="`${location.name} (${location.address})`"
        >
          <a-row :gutter="16">
            <a-col :span="12">
              <a-card title="基本情報">
                <p>地址: {{ location.address }}</p>
                <p v-if="location.executing_company">実行会社: {{ location.executing_company }}</p>
              </a-card>
            </a-col>
            <a-col :span="12">
              <a-card title="財務情報">
                <p v-if="location.contract_amount > 0 && isManager">契約金額: ¥{{ location.contract_amount.toLocaleString() }}</p>
                <p v-if="location.subcontract_amount > 0 && isManager">外注金額: ¥{{ location.subcontract_amount.toLocaleString() }}</p>
                <p  v-if="isManager">利益: ¥{{ location.profit.toLocaleString() }}</p>
                <p v-if="location.total_material_cost > 0 && isManager">材料代合計: ¥{{ location.total_material_cost.toLocaleString() }}</p>
                <p v-if="location.total_subsidiary_material_cost > 0 && isManager">副資材代合計: ¥{{ location.total_subsidiary_material_cost.toLocaleString() }}</p>
              </a-card>
            </a-col>
          </a-row>
          <h3>月別経費:</h3>
          <a-list
              class="expenses-list"
              size="small"
              bordered
              v-for="expense in location.expenses_by_month"
              :key="expense.month"
          >
            <a-list-item>
              {{ expense.month }} -
              <span v-if="isManager">
               総人工費: ¥{{ expense.total_wages.toLocaleString() }},
               その他費用: ¥{{ expense.total_expenses.toLocaleString() }},
               材料代: ¥{{ expense.material_cost.toLocaleString() }},
               副資材代: ¥{{ expense.subsidiary_material_cost.toLocaleString() }}
             </span>
              <span>総人工: {{ expense.total_man_days }} 天</span>
            </a-list-item>
          </a-list>
          <a-list
              class="attendance-list"
              size="small"
              v-for="entry in location.daily_attendance"
              :key="entry.date"
              bordered
              itemLayout="horizontal"
          >
            <template #header>
              <strong>{{ entry.date }}</strong>
            </template>
            <a-list-item v-if="entry.formattedUsers" class="a-list-item">
              <span class="user-info">
                {{ entry.formattedUsers }}
              </span>
            </a-list-item>
            <a-list-item v-else>
              <span class="user-info">
                出勤情報なし
              </span>
            </a-list-item>
          </a-list>
        </a-collapse-panel>
      </a-collapse>
    </a-layout-content>
  </a-layout>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import axios from '@/api/axios';
import dayjs from 'dayjs';
import { useStore } from 'vuex';
import * as XLSX from 'xlsx';


const store = useStore();
const isManager = computed(() => store.state.userInfo.role === 'manager');
const userId = store.state.userInfo.id;
const loading = ref(false);

const selectedMonth = ref(dayjs().format('YYYY-MM'));
const locationStatus = ref('not_completed');
const allLocations = ref({ completed_locations: [], not_completed_locations: [] });

const formattedLocations = computed(() => {
  let locations = [];
  if (locationStatus.value === 'all') {
    locations = [...allLocations.value.completed_locations, ...allLocations.value.not_completed_locations];

  } else if (locationStatus.value === 'completed') {
    locations = allLocations.value.completed_locations;

  } else {
    locations = allLocations.value.not_completed_locations;

  }

  const result = locations.map(location => {
    const filteredExpenses = location.expenses_by_month.filter(expense => dayjs(expense.month).format('YYYY-MM') === selectedMonth.value);

    // 对每个位置的每日出勤记录按日期排序
    const sortedAttendance = location.daily_attendance
        .filter(entry => dayjs(entry.date).format('YYYY-MM') === selectedMonth.value)
        .sort((a, b) => dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1);

    return {
      ...location,
      profit: calculateProfit(location),
      expenses_by_month: filteredExpenses.map(expense => ({
        ...expense,
        total_man_days: calculateTotalManDaysForMonth(expense, location)
      })),
      daily_attendance: sortedAttendance.map(entry => ({
        ...entry,
        formattedUsers: entry.users.map(user => `${user.last_name} ${user.first_name} (${user.attendance_status})`).join(" | ")
      }))
    };
  });

  return result;
});


async function exportToExcel() {
  loading.value = true; // 开始加载动画

  const wb = XLSX.utils.book_new(); // 创建一个新的Excel工作簿
  formattedLocations.value.forEach(location => {
    const monthFilter = selectedMonth.value || dayjs().format('YYYY-MM');
    const filteredDailyAttendance = location.daily_attendance.filter(entry => entry.date.startsWith(monthFilter));
    const filteredExpensesByMonth = location.expenses_by_month.filter(expense => expense.month === monthFilter);

    const ws_data = [
      ["地址", "実行会社", isManager.value ? "契約金額" : "", "外注金額", "利益"],
      [location.address, location.executing_company, isManager.value ? location.contract_amount : "", location.subcontract_amount, location.profit],
      [],
      ["月", "総賃金", "その他の費用", "材料費", "副材料費"]
    ];

    // 添加过滤后的每月开支数据
    filteredExpensesByMonth.forEach(expense => {
      ws_data.push([expense.month, expense.total_wages, expense.total_expenses, expense.material_cost, expense.subsidiary_material_cost]);
    });

    // 添加过滤后的每日出勤数据
    ws_data.push([]);
    ws_data.push(["日付", "出席情報"]);
    filteredDailyAttendance.forEach(attendance => {
      ws_data.push([attendance.date, attendance.formattedUsers]);
    });

    // 创建Excel工作表
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, location.name); // 为每个地点创建一个新的工作表
  });

  // 写入文件并停止加载动画
  setTimeout(() => {
    XLSX.writeFile(wb, `現場出勤記録_${selectedMonth.value || dayjs().format('YYYY-MM')}.xlsx`);
    loading.value = false;
  }, 3000); // 根据实际需要调整延迟
}

onMounted(async () => {
  await fetchData();
});

watch([selectedMonth, locationStatus], () => {
  fetchData();
}, { immediate: true });

async function fetchData() {
  try {
    const response = await axios.post('/report/locations-attendance/', { user_id: userId });
    allLocations.value = response.data;
  } catch (e) {
    console.error('Failed to fetch data:', e);
  }
}

function handleMonthChange(value, dateString) {
  selectedMonth.value = dateString;
}

function calculateProfit(location) {
  const totalExpenses = location.expenses_by_month.reduce((sum, current) => sum + current.total_wages + current.total_expenses, 0);
  return location.subcontract_amount - (location.total_material_cost + location.total_subsidiary_material_cost + totalExpenses);
}

function calculateTotalManDaysForMonth(expense, location) {
  const monthString = expense.month;
  const totalManDays = location.daily_attendance
      .filter(entry => entry.date.startsWith(monthString))
      .reduce((sum, entry) => sum + calculateTotalManDays(entry), 0);
  return totalManDays || 0;
}

function calculateTotalManDays(entry) {
  return entry.users.reduce((sum, user) => {
    return sum + (user.attendance_status === "1天" ? 1 : user.attendance_status === "半天" ? 0.5 : 0);
  }, 0);
}
</script>

<style scoped>
.layout {
  height: 100vh;
}
.header, .filter-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.month-picker {
  width: 100%;
}
</style>
