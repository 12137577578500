<template>
  <a-layout>
    <a-layout-header class="header">
      <div class="logo" />
      <a-page-header class="a-page-header">NKG勤怠システム</a-page-header>
      <a-button type="primary" @click="handleLogout">ログアウト</a-button>
    </a-layout-header>
    <a-layout>
      <a-layout-sider width="200" collapsible v-model:collapsed="isCollapsed" style="background: #fff;">
        <a-menu
            v-model:selectedKeys="selectedKeys2"
            v-model:openKeys="openKeys"
            mode="inline"
            :style="{ height: '100%', borderRight: 0 }"
        >
          <a-menu-item key="0" @click="selectMenu('0')">勤怠</a-menu-item>
          <a-menu-item key="6" v-if="canViewAttendanceRecords" @click="selectMenu('6')">材料登録</a-menu-item>
          <a-sub-menu key="sub1" v-if="isManager">
            <template #title>
              <span>
                管理
              </span>
            </template>
            <a-menu-item key="1" v-if="canViewAttendanceRecords" @click="selectMenu('1')">考勤記録</a-menu-item>
            <a-menu-item key="2" v-if="isManager" @click="selectMenu('2')">従業員情報</a-menu-item>
            <a-menu-item key="3" v-if="isManager" @click="selectMenu('3')">ユーザー登録</a-menu-item>
            <a-menu-item key="4" v-if="isManager" @click="selectMenu('4')">現場登録</a-menu-item>
            <a-menu-item key="5" v-if="isManager" @click="selectMenu('5')">現場出席統計</a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-content style="padding: 0 24px 24px">
        <!-- 子路由内容将在这里显示 -->
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>


<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import axios from '@/api/axios';

const isCollapsed = ref(false);
const selectedKeys2 = ref(['1']);
const openKeys = ref(['sub1']);
const router = useRouter();
const store = useStore();
// const userInfo = computed(() => store.state.userInfo);
// const isManager = computed(() => userInfo.value.role === 'manager');
const loading = ref(false);

async function handleLogout() {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      await axios.post('/auth/logout/', {refresh_token: refreshToken});
    }
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    store.commit('clearUserInfo');
    await router.push('/login');
  } catch (error) {
    console.error('Logout error:', error);
    await router.push('/login');
  }
}

const isManager = computed(() => store.getters.isManager);

const canViewAttendanceRecords = computed(() => {
  return store.getters.isManager || isSupervisor;
});

const isSupervisor = computed(() => store.getters.isSupervisor);


function selectMenu(key) {
  if (!canViewAttendanceRecords.value) {
    console.error('アクセス権限がありません。');
    return;
  }
  switch (key) {
    case '0':
      router.push('/dashboard/checkin-page');
      break;
    case '1':
      router.push('/dashboard/attendance-records');
      break;
    case '2':
      router.push('/dashboard/profile');
      break;
    case '3':
      router.push('/dashboard/user-create');
      break;
    case '4':
      router.push('/dashboard/desktop-location');
      break;
    case '5':
      router.push('/dashboard/desktop-location-attendance');
      break;
    case '6':
      router.push('/dashboard/material-registration');
      break;
    default:
      router.push('/dashboard/checkin-page');
  }
}

onMounted(async () => {
  if (store.state.locations.length === 0) {
    loading.value = true;
    try {
      const response = await axios.get('/api/locations/?is_completed=false');
      store.commit('setLocations', response.data);
    } catch (error) {
      console.error('位置情報の取得に失敗しました。', error);
    }
    loading.value = false;
  }
});
</script>


<style scoped>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.header {
  background: #001529;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.a-page-header {
  color: #ffffff; /* 改变字体颜色为白色 */
  font-size: 20px; /* 增加字体大小 */
  font-weight: bold; /* 字体加粗 */
}


.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

a-layout-sider {
  min-height: 100vh;
}

a-card {
  margin-top: 20px;
}


.event-item > div {
  font-size: 12px;
}
</style>