<template>
  <a-layout class="mobile-attendance-layout">
    <a-layout-header class="mobile-check-in-header">
      <div class="header-content">
        <h1>考勤記録</h1> <!-- 考勤记录 -->
      </div>
    </a-layout-header>
    <a-layout-content style="padding: 24px;">
      <!-- 筛选功能 -->
      <a-row :gutter="16" class="filter-row">
        <a-col :span="24">
          <VueDatePicker v-model="filterMonth"
                         format="yyyy/MM"
                         locale="ja"
                         month-picker
                         week-start="1"
                         auto-apply
                         hide-offset-dates
                         @input="filterData"
                         style="width: 100%;"
                         placeholder="月を選択"/>
            <a-tag color="green">月間総費用: ¥{{ totalExpenses }}</a-tag>
        </a-col>
      </a-row>
      <!-- 考勤记录列表 -->
      <a-list
          :dataSource="filteredAttendanceData"
          :loading="loading"
          itemLayout="horizontal"
          class="attendance-list"
      >
        <a-list-item v-for="item in filteredAttendanceData" :key="item.id">
          <a-list-item-meta
              :title="`日付: ${item.date}`"
              :description="`場所: ${item.location_name}, 出勤時間: ${item.attendance_status}, 飲料費: ${item.drink_expense}, 交通費: ${item.transport_expense}, 日給: ${item.daily_wage}, その他: ${item.other_expenses}, 説明: ${item.description}`"
          />
          <a-button type="default" @click="deleteRecord(item.id)">削除</a-button>
        </a-list-item>
      </a-list>
    </a-layout-content>
  </a-layout>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import axios from '@/api/axios';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import VueDatePicker from "@vuepic/vue-datepicker";


// 使用 Vuex store 获取用户信息
const store = useStore();
const userId = store.state.userInfo.id;
const attendanceData = ref([]);
const filteredAttendanceData = ref([]);


const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1; // getMonth() 返回0-11，所以需要+1
const formattedMonth = `${currentYear}-${currentMonth < 10 ? '0' + currentMonth : currentMonth}`;

// 初始化 filterMonth 为当前月份，格式 "yyyy/MM"
const filterMonth = ref(formattedMonth);

const loading = ref(false);
// 加载考勤数据
const loadAttendanceData = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`/api/user_attendance/${userId}/list/`);
    attendanceData.value = response.data.sort((a, b) => new Date(a.date) - new Date(b.date));
    loading.value = false;
    filterData();
  } catch (error) {
    console.error('获取考勤数据失败', error);
    loading.value = false;
  }
};

// 将日期格式化为统一格式 "YYYY-MM-DD"
function formatTargetMonth(dateValue) {
  if (typeof dateValue === 'string') {
    // 如果传入的是字符串 "YYYY-MM"，直接添加 "-01"
    return `${dateValue}-01`;
  } else if (typeof dateValue === 'object' && dateValue.month !== undefined && dateValue.year) {
    // 如果传入的是对象 { month: M, year: Y }
    // 月份需要加1，因为前端传递的是0-11的值，需要转换为1-12，并格式化为两位数
    const monthPadded = (dateValue.month + 1).toString().padStart(2, '0'); // 确保月份为两位数字
    return `${dateValue.year}-${monthPadded}-01`;
  }
  return null; // 如果输入不合法，返回 null
}


// 根据过滤月份过滤考勤数据
function filterData() {
  const targetMonth = formatTargetMonth(filterMonth.value);

  if (targetMonth) {
    filteredAttendanceData.value = attendanceData.value.filter(item => {
      const itemMonth = `${new Date(item.date).getFullYear()}-${(new Date(item.date).getMonth() + 1).toString().padStart(2, '0')}-01`;
      return itemMonth === targetMonth;
    });
  } else {
    filteredAttendanceData.value = attendanceData.value;
  }
}


const totalExpenses = computed(() => {
  return filteredAttendanceData.value.reduce((sum, record) => {
    return sum + record.drink_expense + record.transport_expense + record.daily_wage + (record.other_expenses || 0);
  }, 0);
});

const deleteRecord = (id) => {
  Modal.confirm({
    title: '削除確認',
    content: 'この記録を削除してもよろしいですか？この操作は取り消しできません。',
    okText: '確認',
    cancelText: 'キャンセル',
    onOk: async () => {
      try {
        await axios.delete(`/api/attendances/${id}/`);
        // データの再読み込み
        await loadAttendanceData();
      } catch (error) {
        console.error('記録の削除に失敗しました', error);
      }
    }
  });
};

watch(filterMonth, filterData);

onMounted(loadAttendanceData);
</script>

<style scoped>

.mobile-check-in-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1890ff;
  color: white;
  padding: 10px;
}

.mobile-attendance-layout {
  max-width: 480px; /* 限制最大宽度以适应移动设备 */
  margin: auto;
}

.header-content {
  text-align: center;
}

.filter-row {
  margin-bottom: 20px;
}

.attendance-list {
  margin-top: 20px;
}
</style>
