<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import jaJP from 'ant-design-vue/es/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

dayjs.locale('ja');


const locale = ref(jaJP);



onMounted(() => {
  document.title = 'NKG勤怠';
});
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>