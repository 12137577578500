import { UAParser } from 'ua-parser-js';
import { useStore } from 'vuex';

export function checkDeviceType() {
    const parser = new UAParser();
    const result = parser.getResult();
    return result.device.type === 'mobile' ? 'mobile' : 'desktop';
}

export function getGlobalUserRole() {
    const store = useStore();
    return store.state.userInfo.role;
}

