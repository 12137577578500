// src/i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
    ja: {
        username: "ユーザー名",
        password: "パスワード",
        login: "ログイン",
        enterUsername: "ユーザー名を入力してください",
        enterPassword: "パスワードを入力してください",
        loginSuccess: "ログイン成功",
        loginError: "ログインに失敗しました。ユーザー名とパスワードを確認してください。"
    }
};

const i18n = createI18n({
    locale: 'ja', // 默认语言设置为日文
    messages,
});

export default i18n;
