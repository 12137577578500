// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import store from './store';

const app = createApp(App);
import { Layout, Row, Col, List, DatePicker } from 'ant-design-vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

app.use(router);
app.use(i18n);
app.use(Antd);
app.mount('#app');
app.use(store);
app.use(Layout);
app.use(Row);
app.use(Col);
app.use(List);
app.use(DatePicker);
app.component('VueDatePicker', VueDatePicker);

