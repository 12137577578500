<template>
  <a-layout class="layout">
    <a-layout-content style="padding: 24px;">
      <a-menu :selected-keys="selectedKeys" :theme="theme" mode="horizontal">
        <a-menu-item
            class="a-menu-item"
            v-for="item in menuItems"
            :key="item.key"
        >
          <router-link :to="item.url">
            <component :is="item.icon" />
            {{ item.label }}
          </router-link>
        </a-menu-item>
      </a-menu>
      <router-view></router-view>
    </a-layout-content>
  </a-layout>
</template>

<script setup>
import axios from "@/api/axios";
import { ref, computed, onMounted, h } from 'vue';
import { useStore } from 'vuex';
import { UserOutlined, VideoCameraOutlined, UploadOutlined } from '@ant-design/icons-vue';

const store = useStore();
const theme = ref('dark');
const selectedKeys = ref(['1']);

const loadLocations = async () => {
  if (store.state.locations.length === 0) {
    try {
      const response = await axios.get('/api/locations/?is_completed=false');
      store.commit('setLocations', response.data);
    } catch (error) {
      console.error('位置情報の取得に失敗しました。', error);
    }
  }
};

onMounted(() => {
  loadLocations();
});

const menuItems = computed(() => [
  {
    key: 'check-in',
    icon: h(UserOutlined),
    label: '勤怠',
    url: '/mobile-dashboard/check-in',
    visible: true,
  },
  {
    key: 'user-info',
    icon: h(VideoCameraOutlined),
    label: 'ユーザー情報',
    url: '/mobile-dashboard/user-info',
    visible: true,
  },
  {
    key: 'attendance-list',
    icon: h(UploadOutlined),
    label: '勤怠記録',
    url: '/mobile-dashboard/attendance-list',
    visible: true,
  },
  {
    key: 'site-management',
    icon: h(UploadOutlined),
    label: '現場管理',
    url: '/mobile-dashboard/site-management',
    visible: store.getters.isManager,
  },
  {
    key: 'location-attendance',
    icon: h(UploadOutlined),
    label: '現場出席統計',
    url: '/mobile-dashboard/location-attendance',
    visible: store.getters.isManager || store.getters.isSupervisor,
  },
  {
    key: 'material-registration',
    icon: h(UploadOutlined),
    label: '材料登録',
    url: '/mobile-dashboard/material-registration',
    visible: store.getters.isManager || store.getters.isSupervisor,
  }
].filter(item => item.visible));
</script>


<style scoped>
.layout {
  height: 100vh;
}

.a-menu-item {
  min-width: 120px;
}
</style>
