<template>
  <a-row gutter="16">
    <a-col :span="24">
      <a-calendar v-model:value="selectTime" :disabled-date="disabledDate">
        <template #dateCellRender="{ current }">
          <ul class="events">
            <li v-for="item in getListData(current)" :key="item.content" class="event-item">
              <a-tag>出勤時間: {{ item.attendance_status }}</a-tag>
              <a-tag>場所: {{ item.locationName }}</a-tag>
              <a-tag>飲料費: {{ item.drink_expense }}</a-tag>
              <a-tag>交通費: {{ item.transport_expense }}</a-tag>
              <a-tag>日給: {{ item.daily_wage }}</a-tag>
              <a-tag>その他: {{ item.other_expenses }}</a-tag>
            </li>
          </ul>
        </template>
      </a-calendar>
    </a-col>
    <a-col :span="8">
      <a-card title="ユーザー情報" bordered>
        <a-table :data-source="dataSource" :columns="columns" pagination="false" />
      </a-card>
    </a-col>
    <a-col :span="8">

      <a-card title="自主チェックイン" bordered>
        <a-form-item label="当日基本給">
          <a-tag color="black">¥{{ baseDailyWage.toLocaleString() }}</a-tag>
        </a-form-item>
        <a-form-item label="場所">
          <a-select v-model:value="selectedLocation" :options="locationOptions" placeholder="場所を選択してください" />
        </a-form-item>

        <!-- 日期选择器 -->
        <a-form-item label="日付">
          <a-date-picker
              v-model:value="selectTime"
              format="YYYY-MM-DD"
              :disabled-date="disabledDate"
          />
        </a-form-item>

        <!-- 出勤时间选择器 -->

        <a-form-item label="出勤時間">
          <a-select v-model:value="attendanceStatus" @change="handleAttendanceStatusChange" placeholder="出勤時間を選択してください" style="width: 200px;">
            <a-select-option value="1天">1日</a-select-option>
            <a-select-option value="半天">半日</a-select-option>
            <a-select-option value="leave">休暇</a-select-option>
            <a-select-option value="none">その他</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="飲料費">
          <a-input-number v-model:value="drinkExpense" :min="0" :disabled="attendanceStatus.value === 'none'" />
        </a-form-item>
        <a-form-item label="交通費">
          <a-input-number v-model:value="transportExpense" :min="0" :disabled="attendanceStatus.value === 'none'" />
        </a-form-item>
        <a-form-item label="その他">
          <a-input-number v-model:value="other_expenses" :min="0" />
        </a-form-item>
        <a-form-item label="その他の説明">
          <a-input v-model:value="description" :min="0" />
        </a-form-item>
        <!-- 提交按钮 -->
        <a-form-item>
          <a-button type="primary" @click="submitAttendance">チェックイン</a-button>
        </a-form-item>
      </a-card>
    </a-col>
  </a-row>
</template>

<script setup>
import {ref, computed, onMounted} from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import axios from '@/api/axios';
import { message } from 'ant-design-vue';

const store = useStore();
const selectTime = ref(dayjs());
const selectedLocation = ref(null);
const attendanceStatus = ref('');
const drinkExpense = ref(300);
const transportExpense = ref(0);
const other_expenses = ref(0);
const attendanceData = ref([]);
const isSubmitting = ref(false);
const userInfo = computed(() => store.state.userInfo);
const loading = ref(false);
const description = ref();



const locationOptions = computed(() => store.state.locations.map(location => ({
  label: location.name,
  value: location.id
})));

const getListData = (current) => {
  const dateStr = current.format('YYYY-MM-DD');
  return attendanceData.value.filter(item => item.date === dateStr).map(item => ({
    date: item.date,
    locationName: item.location_name,
    drink_expense: item.drink_expense,
    transport_expense: item.transport_expense,
    daily_wage: item.daily_wage,
    attendance_status: item.attendance_status,
    other_expenses: item.other_expenses
  }));
};

const disabledDate = (current) => {
  const startOfCurrentMonth = dayjs().startOf('month');
  const endOfCurrentMonth = dayjs().endOf('month');
  return current.isBefore(startOfCurrentMonth, 'day') || current.isAfter(endOfCurrentMonth, 'day');
};

const baseDailyWage = computed(() => store.state.userInfo.daily_pay);

const dailyWage = computed(() => {
  switch (attendanceStatus.value) {
    case '1天':
      return baseDailyWage.value;
    case '半天':
      return baseDailyWage.value * 0.5;
    case 'leave':
    case 'none':
      return 0;
    default:
      return baseDailyWage.value; // 默认返回全日工资
  }
});


const submitAttendance = async () => {
  if (!selectTime.value || !selectedLocation.value) {
    message.error('必要なフィールドをすべて入力してください。');
    return;
  }
  isSubmitting.value = true;
  try {
    const checkUrl = `/api/check-attendance/check-attendance/?employee_id=${store.state.userInfo.id}&date=${selectTime.value.format('YYYY-MM-DD')}&location_id=${selectedLocation.value}`;
    const checkResponse = await axios.get(checkUrl);
    const { exists, id } = checkResponse.data;
    let apiUrl = '/api/attendances/';
    let method = 'post';
    let data = {
      employee: store.state.userInfo.username,
      location: selectedLocation.value,
      date: selectTime.value.format('YYYY-MM-DD'),
      attendance_status: attendanceStatus.value,
      drink_expense: drinkExpense.value,
      transport_expense: transportExpense.value,
      daily_wage: dailyWage.value,
      other_expenses: other_expenses.value,
      description: description.value
    };
    if (exists) {
      method = 'put';
      apiUrl += `${id}/`;
    }
    await axios({ method, url: apiUrl, data });
    message.success('チェックインが成功しました。');
  } catch (error) {
    message.error('チェックインに失敗しました。もう一度試してください。');
  } finally {
    isSubmitting.value = false;
  }
};

const handleAttendanceStatusChange = (value) => {
  // 如果选择的是 'none'，则将费用设置为0并禁止修改
  if (value === 'none') {
    drinkExpense.value = 0;
    transportExpense.value = 0;
    dailyWage.value = 0;
  } else {
    drinkExpense.value = 300; // 默认饮料费用
    transportExpense.value = 0; // 默认交通费用
    dailyWage.value = 0; // 默认当日基本薪资
  }
};

const loadAttendanceData = async () => {
  const userId = store.state.userInfo.id;
  try {
    const response = await axios.get(`/api/user_attendance/${userId}/list/`);
    attendanceData.value = response.data;
  } catch (error) {
    console.error('获取考勤数据失败', error);
  }
};

const dataSource = computed(() => {
  if (!userInfo.value) {
    return [];
  }
  return [
    {key: '1', item: 'ユーザー名', detail: userInfo.value.username},
    {key: '2', item: 'メール', detail: userInfo.value.email},
    {key: '3', item: '名前', detail: `${userInfo.value.last_name} ${userInfo.value.first_name}`},
    {key: '4', item: '電話番号', detail: userInfo.value.phone},
    {key: '5', item: '役割', detail: userInfo.value.role}
  ];
});

const columns = [
  {title: '項目', dataIndex: 'item', key: 'item'},
  {title: '内容', dataIndex: 'detail', key: 'detail'}
];



// 在组件加载时加载数据
onMounted(() => {
  loadAttendanceData();
});

onMounted(async () => {
  if (store.state.locations.length === 0) {
    loading.value = true;
    try {
      const response = await axios.get('/api/locations/?is_completed=false');
      // 使用响应数据设置locations状态
      store.commit('setLocations', response.data);
    } catch (error) {
      console.error('位置情報の取得に失敗しました。', error);
    }
    loading.value = false;
  }
});
</script>

<style scoped>
/* 样式 */
</style>
