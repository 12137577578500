<template>
  <a-layout>
    <a-layout-content style="padding: 24px">
      <div class="form-container">
        <div class="form-item">
          <label>ユーザー名:</label>
          <a-input v-model:value="formData.username" />
        </div>
        <div class="form-item">
          <label>パスワード:</label>
          <a-input v-model:value="formData.password_hash"  />
        </div>
        <div class="form-item">
          <label>メール:</label>
          <a-input v-model:value="formData.email" />
        </div>
        <div class="form-item">
          <label>名:</label>
          <a-input v-model:value="formData.first_name" />
        </div>
        <div class="form-item">
          <label>姓:</label>
          <a-input v-model:value="formData.last_name" />
        </div>
        <div class="form-item">
          <label>電話番号:</label>
          <a-input v-maska="'###-####-####'" v-model:value="formData.phone" />
        </div>
        <div class="form-item">
          <label>役割:</label>
          <a-select v-model:value="formData.role">
            <a-select-option value="staff">職員</a-select-option>
            <a-select-option value="supervisor">職長</a-select-option>
            <a-select-option value="manager">管理者</a-select-option>
          </a-select>
        </div>
        <div class="form-item">
          <label>日給:</label>
          <a-input-number v-model:value="formData.daily_pay" />
        </div>

        <div class="form-actions">
          <a-button type="primary" @click="handleSubmit">確認</a-button>
          <a-button @click="cancelForm">キャンセル</a-button>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script setup>
import { ref } from 'vue';
import axios from '@/api/axios';
import { vMaska } from "maska/vue"

const formData = ref({
  username: '',
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  role: 'staff',
  daily_pay: 0,
  password_hash: '8etg9f8r',
});

const handleSubmit = async () => {
  try {
    const url = '/auth/register/';
    const response = await axios.post(url, formData.value);
    if (response.status === 200 || response.status === 201) {
      console.log('User created successfully');
      alert('ユーザーが正常に作成されました。');
    } else {
      throw new Error('Operation failed with status: ' + response.status);
    }
  } catch (error) {
    console.error('Error:', error);
    alert('ユーザーの作成に失敗しました。もう一度お試しください。');
  }
};

const cancelForm = () => {
  // 清空表单数据
  formData.value = {
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    role: 'staff',
    daily_pay: 0,
    password_hash: '8etg9f8r',
  };
};
</script>

<style scoped>
.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.form-item {
  margin-bottom: 16px;
}

.form-item label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}
</style>
