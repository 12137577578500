import axios from 'axios';

const instance = axios.create({
    // baseURL: 'http://18.182.6.240:8000/',
    baseURL: 'http://www.nkgcorporation.com/',
});

// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        // 检查请求的URL是否为登录URL，确保登录请求不携带Authorization头
        if (!config.url.endsWith('/auth/login/')) {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => {
        // 请求错误处理
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    response => {
        // 响应成功直接返回response
        return response;
    },
    error => {
        // 统一处理错误响应
        if (error.response && error.response.status === 401) {
            // 如果响应状态码为401，可能是令牌无效或过期
            localStorage.removeItem('token');  // 清除本地存储的令牌
        }
        return Promise.reject(error);
    }
);

export default instance;
