<template>
  <a-layout class="layout" >
    <a-layout-content style="padding: 24px">
      <a-row>
        <a-col :span="24">
          <a-table :columns="columns" :dataSource="filteredRecords" rowKey="record => record.id" >
            <template #title>
              <a-space>
                <a-month-picker v-model:value="selectedMonth" placeholder="月を選択"/>
                <a-button @click="handleSearch" type="primary" >検索</a-button>
                <a-select v-model:value="selectedLocation" placeholder="場所を選択" style="width: auto;">
                  <a-select-option :value="null">すべての場所</a-select-option>
                  <a-select-option v-for="location in locationNames" :key="location" :value="location">
                    {{ location }}
                  </a-select-option>
                </a-select>
                <a-input-search v-model:value="searchText" placeholder="従業員を検索..." style="width: auto;" />
                <a-button @click="showNewModal" type="primary">追加</a-button>
                <a-button @click="exportToExcel" type="primary">Excelにエクスポート</a-button>
              </a-space>
            </template>
            <template #[`actions`]="{ record }">
              <a-space>
                <a-button type="link" @click="showEditModal(record)">編集</a-button>
                <a-button type="link" @click="confirmDelete(record.id)" danger>削除</a-button>
              </a-space>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-layout-content>
  </a-layout>

  <!-- 新增记录模态框 -->
  <a-modal
      title="新しいレコード追加"
      v-model:open="isNewModalVisible"
      @ok="handleNewRecord"
      ok-text="追加"
      cancel-text="キャンセル">
    <a-form :model="newRecord">
      <a-form-item label="従業員">
        <a-select v-model:value = "newRecord.employee" placeholder="従業員を選択" style="width: 100%;">
          <a-select-option v-for="option in managerOptions" :key="option.value" :value="option.value">
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="日付">
        <VueDatePicker v-model="newRecord.date"
                       format="yyyy/MM/dd"
                       locale="ja"
                       model-type="yyyy-MM-dd"
                       week-start="0"
                       :day-class="getDayClass"
                       auto-apply
                       disable-year-select
                       hide-offset-dates
                       style="width: 100%;" />
      </a-form-item>
      <a-form-item label="場所">
        <a-select v-model:value="newRecord.location" placeholder="場所を選択" style="width: 100%;">
          <a-select-option v-for="option in locationOptions" :key="option.value" :value="option.value">
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="出勤状況">
        <a-select v-model:value="newRecord.attendance_status" style="width: 100%;">
          <a-select-option value="1天">1日</a-select-option>
          <a-select-option value="半天">半日</a-select-option>
          <a-select-option value="leave">休暇</a-select-option>
          <a-select-option value="none">その他</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="飲料費">
        <a-input-number v-model:value="newRecord.drink_expense" style="width: 100%;" min="0" />
      </a-form-item>
      <a-form-item label="交通費">
        <a-input-number v-model:value="newRecord.transport_expense" style="width: 100%;" min="0" />
      </a-form-item>
      <a-form-item label="日給">
        <a-input-number v-model:value="newRecord.daily_wage" style="width: 100%;" min="0" />
      </a-form-item>
      <a-form-item label="その他">
        <a-input-number v-model:value="newRecord.other_expenses" style="width: 100%;" min="0" />
      </a-form-item>
      <a-form-item label="説明">
        <a-textarea v-model:value="newRecord.description" rows="3" />
      </a-form-item>
      <!-- 根据需要添加更多表单项目 -->
    </a-form>
  </a-modal>

  <!-- 编辑记录模态框 -->
  <a-modal
      title="レコード編集"
      v-model:open="isModalVisible"
      @ok="handleEdit"
      ok-text="更新"
      cancel-text="キャンセル">
    <a-form :model="editRecord">
      <a-form-item label="従業員">
        <a-input v-model:value="editRecord.employee_full_name" disabled />
      </a-form-item>
      <a-form-item label="日付">
        <VueDatePicker v-model="editRecord.date"
                       format="yyyy/MM/dd"
                       locale="ja"
                       model-type="yyyy-MM-dd"
                       week-start="0"
                       :day-class="getDayClass"
                       auto-apply
                       disable-month-year-select
                       hide-offset-dates
                       style="width: 100%;" />
      </a-form-item>
      <a-form-item label="場所">
        <a-select v-model:value="editRecord.location" placeholder="場所を選択" style="width: 100%;">
          <a-select-option v-for="option in locationOptions" :key="option.value" :value="option.value">
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="出勤状況">
        <a-select v-model:value="editRecord.attendance_status" style="width: 100%;">
          <a-select-option value="1天">1日</a-select-option>
          <a-select-option value="半天">半日</a-select-option>
          <a-select-option value="leave">休暇</a-select-option>
          <a-select-option value="none">その他</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="飲料費">
        <a-input-number v-model:value="editRecord.drink_expense" style="width: 100%;" min="0" />
      </a-form-item>
      <a-form-item label="交通費">
        <a-input-number v-model:value="editRecord.transport_expense" style="width: 100%;" min="0" />
      </a-form-item>
      <a-form-item label="日給">
        <a-input-number v-model:value="editRecord.daily_wage" style="width: 100%;" min="0" />
      </a-form-item>
      <a-form-item label="その他">
        <a-input-number v-model:value="editRecord.other_expenses" style="width: 100%;" min="0" />
      </a-form-item>
      <a-form-item label="説明">
        <a-textarea v-model:value="editRecord.description" rows="3" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>


<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import axios from '@/api/axios';
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex';
import * as XLSX from 'xlsx';

const selectedMonth = ref(null);
const searchText = ref('');
const selectedLocation = ref(null);
const records = ref([]);
const editRecord = ref({});
const newRecord = ref({});
const isModalVisible = ref(false); // 统一使用这个变量来控制模态框的显示
const modalAction = ref('add'); // 添加或编辑的行为状态
const isNewModalVisible = ref(false);
const isLoading = ref(false);


const store = useStore();
const locationOptions = computed(() => store.state.locations.map(location => ({
  label: location.name,
  value: location.id
})));

const columns = [
  {
    title: '従業員',
    dataIndex: 'employee_full_name',
    sorter: (a, b) => a.employee_full_name.localeCompare(b.employee_full_name),
  },
  {
    title: '日付',
    dataIndex: 'date',
    sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  },
  {
    title: '場所',
    dataIndex: 'location_name',
  },
  {
    title: '出勤状況',
    dataIndex: 'attendance_status',
  },
  {
    title: '飲料費',
    dataIndex: 'drink_expense',
  },
  {
    title: '交通費',
    dataIndex: 'transport_expense',
  },
  {
    title: '日給',
    dataIndex: 'daily_wage',
  },
  {
    title: 'その他',
    dataIndex: 'other_expenses',
    sorter: (a, b) => a.other_expenses - b.other_expenses,
  },
  {
    title: '説明',
    dataIndex: 'description',
  },
  {
    title: '操作',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
];



// 条件分岐が必要なので、関数の戻り値を利用
const getDayClass = (date) => {
  const weekDay = new Date(date).getDay();
  if (weekDay == 6) {
    // 土曜日の場合、classに"saturday"を追加
    return 'saturday';
  }
  if (weekDay == 0) {
    // 日曜日の場合、classに"sunday"を追加
    return 'sunday';
  }
  return '';
};




const showNewModal = () => {
  const today = new Date();
  const formattedDate = today.toISOString().slice(0, 10);  // YYYY-MM-DD 格式

  newRecord.value = {
    employee: '', // 确保此项为预选或为空
    date: formattedDate, // 默认设置为今天的日期，格式为字符串
    location: '', // 确保此项为预选或为空
    attendance_status: '',
    drink_expense: 300,
    transport_expense: 0,
    daily_wage: 0,
    other_expenses: 0,
    description: ''
  };
  modalAction.value = 'add';
  isNewModalVisible.value = true;
  fetchManagers();
};


const managerOptions = ref([]);
// Fetch the list of managers for the select component
const fetchManagers = async () => {
  try {
    const response = await axios.get('/auth/managerlist/');
    managerOptions.value = response.data.map(manager => ({
      label: `${manager.last_name} ${manager.first_name}`,
      value: manager.username
    }));
  } catch (error) {
    console.error('Failed to fetch manager data:', error);
    Modal.error({
      content: '管理者データの取得に失敗しました。',
    });
  }
};

// 显示编辑记录的模态框
const showEditModal = (record) => {
  editRecord.value = { ...record };
  modalAction.value = 'edit';
  isModalVisible.value = true;
};


// 处理新增记录
const handleNewRecord = async () => {
  console.log('Adding new record'); // 确认函数被触发
  try {
    const response = await axios.post('/api/attendances/', newRecord.value);
    if (response.status === 201) {
      Modal.success({
        content: '記録が正常に追加されました。',
      });
      isNewModalVisible.value = false; // 关闭模态框
      await handleSearch(); // 刷新数据
    } else {
      throw new Error('Response status is not 200');
    }
  } catch (error) {
    console.error('Failed to add new record:', error);
    Modal.error({
      content: '新しい記録の追加に失敗しました。',
    });
  }
};

const handleEdit = async () => {
  console.log('Editing record'); // 确认函数被触发
  try {
    const response = await axios.put(`/api/attendances/${editRecord.value.id}/`, editRecord.value);
    if (response.status === 200) {
      Modal.success({
        content: '記録が正常に更新されました。',
      });
      isModalVisible.value = false; // 关闭模态框
      await handleSearch(); // 刷新数据
    } else {
      throw new Error('Response status is not 200');
    }
  } catch (error) {
    console.error('Failed to update record:', error);
    Modal.error({
      content: '記録の更新に失敗しました。',
    });
  }
};


const confirmDelete = (id) => {
  Modal.confirm({
    title: '本当に削除しますか？',
    content: 'この操作は元に戻せません。',
    onOk() {
      handleDelete(id);
    }
  });
};

const handleDelete = async (id) => {
  try {
    await axios.delete(`/api/attendances/${id}/`);
    console.log('Deleted record with ID:', id);
    await handleSearch(); // テーブルデータを更新
  } catch (error) {
    console.error('Failed to delete record:', error);
  }
};

const locationNames = computed(() => {
  const names = new Set();
  records.value.forEach(record => {
    names.add(record.location_name);
  });
  return Array.from(names);
});

const filteredRecords = computed(() => {
  return records.value.filter(record => {
    const matchesLocation = !selectedLocation.value || record.location_name === selectedLocation.value;
    const matchesName = !searchText.value || record.employee_full_name.includes(searchText.value);
    return matchesLocation && matchesName;
  });
});

const exportToExcel = () => {
  if (!selectedMonth.value) {
    console.error('No month selected');
    return;
  }

  const year = selectedMonth.value.year();
  const month = selectedMonth.value.month() + 1;
  const filteredByMonth = records.value.filter(record => {
    const recordDate = new Date(record.date);
    return recordDate.getFullYear() === year && recordDate.getMonth() + 1 === month;
  });

  const groupedByEmployee = filteredByMonth.reduce((acc, record) => {
    if (!acc[record.employee_full_name]) {
      acc[record.employee_full_name] = [];
    }
    acc[record.employee_full_name].push(record);
    return acc;
  }, {});

  const workbook = XLSX.utils.book_new();

  for (const [employeeName, employeeRecords] of Object.entries(groupedByEmployee)) {
    const worksheetData = employeeRecords.map(record => ({
      "日付": record.date,
      "場所": record.location_name,
      "出勤状況": record.attendance_status,
      "飲料費": record.drink_expense,
      "交通費": record.transport_expense,
      "日給": record.daily_wage,
      "その他": record.other_expenses,
      "説明": record.description
    }));

    // 统计信息
    const totalAttendanceDays = employeeRecords.reduce((sum, record) => {
      if (record.attendance_status === '1天') return sum + 1;
      if (record.attendance_status === '半天') return sum + 0.5;
      return sum;
    }, 0);

    const totalWage = employeeRecords.reduce((sum, record) => sum + record.daily_wage, 0);
    const totalTransportExpense = employeeRecords.reduce((sum, record) => sum + record.transport_expense, 0);
    const totalDrinkExpense = employeeRecords.reduce((sum, record) => sum + record.drink_expense, 0);
    const totalOtherExpenses = employeeRecords.reduce((sum, record) => sum + record.other_expenses, 0);

    worksheetData.push({
      "日付": "統計",
      "場所": "",
      "出勤状況": totalAttendanceDays,
      "飲料費": totalDrinkExpense,
      "交通費": totalTransportExpense,
      "日給": totalWage,
      "その他": totalOtherExpenses,
      "説明": ""
    });

    // 计算所有费用的总和并添加最后一行
    const totalExpenses = totalWage + totalTransportExpense + totalDrinkExpense + totalOtherExpenses;
    worksheetData.push({
      "日付": "総合計",
      "場所": "",
      "出勤状況": "",
      "飲料費": "",
      "交通費": "",
      "日給": "",
      "その他": "",
      "説明": `総合計費用: ${totalExpenses}`
    });

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, employeeName);
  }

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  saveAsExcel(excelBuffer, `Attendance_${year}_${month}.xlsx`);
};

const saveAsExcel = (buffer, filename) => {
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};



watch(selectedLocation, () => {
  filteredRecords.value; // 再計算をトリガー
});

watch(searchText, () => {
  filteredRecords.value; // 再計算をトリガー
});

const handleSearch = async () => {
  isLoading.value = true; // 确认函数被触发
  if (!selectedMonth.value) {
    console.error('No month selected');
    return;
  }

  const year = selectedMonth.value.year();
  const month = selectedMonth.value.month() + 1; // 月份是从0开始的，所以需要加1

  // console.log(`Searching for year: ${year}, month: ${month}`); // 显示搜索的年份和月份

  try {
    const response = await axios.get('/api/attendances/', {
      params: {
        year: year,
        month: month,
      }
    });
    records.value = response.data;
    // console.log('Data fetched successfully:', response.data); // 显示获取的数据
  } catch (error) {
    console.error('Failed to fetch data:', error);
  }
  finally {
    isLoading.value = false; // ローディング終了
  }
};


onMounted(handleSearch);
onMounted(fetchManagers);

</script>

<style scoped>
.layout {
  height: 100%;
}
</style>