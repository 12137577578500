<template>
  <a-layout>
    <a-layout-content class="a-layout-content">
      <div class="filter-container">
        <a-select v-model:value="filterActive" class="a-select" @change="filterUsers">
          <a-select-option value="all">全ユーザー</a-select-option>
          <a-select-option value="true">アクティブユーザー</a-select-option>
          <a-select-option value="false">非アクティブユーザー</a-select-option>
        </a-select>
        <a-input-search
            placeholder="ユーザー情報を検索"
            class="search-input"
            @search="onSearch"
            style="width: 300px; margin-left: 16px;"
        />
        <a-select v-model:value="searchField" class="search-field-select" @change="filterUsers">
          <a-select-option value="username">ユーザー名</a-select-option>
          <a-select-option value="last_name">姓</a-select-option>
          <a-select-option value="first_name">名</a-select-option>
        </a-select>
      </div>

      <!-- 使用a-descriptions来显示数据 -->
      <div v-for="user in filteredUsers" :key="user.id" class="user-info-container">
        <a-descriptions bordered column="1" size="small">
          <a-descriptions-item label="ユーザー名">{{ user.username }}</a-descriptions-item>
          <a-descriptions-item label="姓">{{ user.last_name }}</a-descriptions-item>
          <a-descriptions-item label="名">{{ user.first_name }}</a-descriptions-item>
          <a-descriptions-item label="電話番号">{{ user.phone }}</a-descriptions-item>
          <a-descriptions-item label="メール">{{ user.email }}</a-descriptions-item>
          <a-descriptions-item label="役割">{{ user.role }}</a-descriptions-item>
          <a-descriptions-item label="日給">{{ user.daily_pay }}</a-descriptions-item>
          <a-descriptions-item label="ステータス">{{ renderIsActive(user.is_active) }}</a-descriptions-item>
          <a-descriptions-item>
            <a-button type="primary" @click="showEditModal(user)">編集</a-button>
          </a-descriptions-item>
        </a-descriptions>
      </div>

      <a-modal
          title="ユーザー情報の編集"
          v-model:visible="isModalVisible"
          @ok="handleEdit"
          @cancel="handleCancel"
          ok-text="保存"
          cancel-text="キャンセル"
          class="edit-modal"
      >
        <a-form model="editForm" layout="vertical" class="edit-form">
          <a-form-item label="ユーザー名">
            <a-input v-model:value="editForm.username" placeholder="ユーザー名を入力してください"></a-input>
          </a-form-item>
          <a-form-item label="姓">
            <a-input v-model:value="editForm.last_name" placeholder="姓を入力してください"></a-input>
          </a-form-item>
          <a-form-item label="名">
            <a-input v-model:value="editForm.first_name" placeholder="名を入力してください"></a-input>
          </a-form-item>
          <a-form-item label="電話番号">
            <a-input v-model:value="editForm.phone" placeholder="電話番号を入力してください"></a-input>
          </a-form-item>
          <a-form-item label="メール">
            <a-input v-model:value="editForm.email" placeholder="メールアドレスを入力してください"></a-input>
          </a-form-item>
          <a-form-item label="役割">
            <a-select v-model:value="editForm.role">
              <a-select-option value="manager">管理者</a-select-option>
              <a-select-option value="staff">従業員</a-select-option>
              <a-select-option value="supervisor">レギュレータ</a-select-option>

              <!-- 你可以根据需要添加更多角色选项 -->
            </a-select>
          </a-form-item>
          <a-form-item label="日給">
            <a-input v-model:value="editForm.daily_pay" placeholder="日給を入力してください"></a-input>
          </a-form-item>
          <a-form-item label="ステータス">
            <a-select v-model:value="editForm.is_active">
              <a-select-option value="true">アクティブ</a-select-option>
              <a-select-option value="false">非アクティブ</a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
    </a-layout-content>
  </a-layout>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from '@/api/axios';

const users = ref([]);
const filteredUsers = ref([]);
const filterActive = ref('true');
const isModalVisible = ref(false);
const editForm = ref({});
const searchQuery = ref('');
const searchField = ref('username');

function renderIsActive(isActive) {
  return isActive ? 'アクティブ' : '非アクティブ';
}

function fetchUsers() {
  axios.get('/auth/admin-users/').then((response) => {
    users.value = response.data;
    filterUsers();
  });
}

function filterUsers() {
  filteredUsers.value = users.value.filter((user) => {
    const isActiveMatch = filterActive.value === 'all' || String(user.is_active) === filterActive.value;
    const searchMatch =
        !searchQuery.value || user[searchField.value].toLowerCase().includes(searchQuery.value.toLowerCase());
    return isActiveMatch && searchMatch;
  });
}

function onSearch(value) {
  searchQuery.value = value;
  filterUsers();
}

function showEditModal(user) {
  editForm.value = { ...user };
  isModalVisible.value = true;
}

function handleEdit() {
  axios.patch(`/auth/admin-users/${editForm.value.id}/`, editForm.value).then(() => {
    fetchUsers();
    isModalVisible.value = false;
  }).catch(error => {
    console.error('Update error:', error);
  });
}


function handleCancel() {
  isModalVisible.value = false;
}

onMounted(fetchUsers);
</script>

<style scoped>
.user-info-container {
  margin-bottom: 16px;
}
</style>
